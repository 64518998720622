:root {
  --green: rgba(116, 143, 86, 1);
  --green-20: rgba(116, 143, 86, 0.2);
  --green-40: rgba(116, 143, 86, 0.4);
  --green-60: rgba(116, 143, 86, 0.6);
  --green-80: rgba(116, 143, 86, 0.8);
  --green-95: rgba(116, 143, 86, 0.95);
  --orange: rgba(238, 122, 49, 1);
  --orange-10: rgba(238, 122, 49, 0.1);
  --orange-40: rgba(238, 122, 49, 0.4);
  --orange-80: rgba(238, 122, 49, 0.8);
  --orange-95: rgba(238, 122, 49, 0.95);
  --disabled: rgb(188, 188, 188);
  --error: rgb(168, 0, 0);
  --invalid: rgb(226, 76, 76);
  --error-10: rgba(238, 49, 49, 0.1);
  --brown: #806045;
  --primary-color: var(--orange);
  --delivery-location-1: #748F56;
  --delivery-location-2: #6F5943;
  --delivery-location-3: #EE7A31;
  --delivery-location-4: #8C5BA6;
  --delivery-location-5: #9C9B56;
  --delivery-location-6: #477AA7;
  --delivery-location-7: #F0608C;
}

.m-0-5 {
  margin: 0.125rem !important;
}


.color-orange {
  color: var(--orange) !important;
}

.brown {
  color: var(--brown) !important;
}

.color-delivery-location-1 {
  color: var(--delivery-location-1) !important;
}

.color-delivery-location-2 {
  color: var(--delivery-location-2) !important;
}

.color-delivery-location-3 {
  color: var(--delivery-location-3) !important;
}

.color-delivery-location-4 {
  color: var(--delivery-location-4) !important;
}

.color-delivery-location-5 {
  color: var(--delivery-location-5) !important;
}

.color-delivery-location-6 {
  color: var(--delivery-location-6) !important;
}

.color-delivery-location-7 {
  color: var(--delivery-location-7) !important;
}

.bg-delivery-location-1 {
  background-color: var(--delivery-location-1) !important;
}

.bg-delivery-location-2 {
  background-color: var(--delivery-location-2) !important;
}

.bg-delivery-location-3 {
  background-color: var(--delivery-location-3) !important;
}

.bg-delivery-location-4 {
  background-color: var(--delivery-location-4) !important;
}

.bg-delivery-location-5 {
  background-color: var(--delivery-location-5) !important;
}

.bg-delivery-location-6 {
  background-color: var(--delivery-location-6) !important;
}

.bg-delivery-location-7 {
  background-color: var(--delivery-location-7) !important;
}

.border-delivery-location-1 {
  border-color: var(--delivery-location-1) !important;
}

.border-delivery-location-2 {
  border-color: var(--delivery-location-2) !important;
}

.border-delivery-location-3 {
  border-color: var(--delivery-location-3) !important;
}

.border-delivery-location-4 {
  border-color: var(--delivery-location-4) !important;
}

.border-delivery-location-5 {
  border-color: var(--delivery-location-5) !important;
}

.border-delivery-location-6 {
  border-color: var(--delivery-location-6) !important;
}

.border-delivery-location-7 {
  border-color: var(--delivery-location-7) !important;
}

.border-orange {
  border-color: var(--orange) !important;
}

.bg-orange {
  background-color: var(--orange) !important;
}

.bg-orange-80 {
  background-color: var(--orange-80) !important;
}

.bg-green {
  background-color: var(--green) !important;
}

.color-green {
  color: var(--green) !important;
}

.border-green {
  border-color: var(--green) !important;
}

.border-brown {
  border-color: var(--brown) !important;
}

.border-green-alpha-40 {
  border-color: var(--green-40) !important;
}

.w-1-5rem {
  width: 1.5rem !important;
}

.h-1-5rem {
  height: 1.5rem !important;
}

.min-w-1rem { min-width: 1rem !important;}
.min-w-2rem { min-width: 2rem !important;}
.min-w-3rem { min-width: 3rem !important;}
.min-w-4rem { min-width: 4rem !important;}
.min-w-5rem { min-width: 5rem !important;}
.min-w-6rem { min-width: 6rem !important;}
.min-w-7rem { min-width: 7rem !important;}


.min-h-1rem { min-height: 1rem !important;}
.min-h-2rem { min-height: 2rem !important;}
.min-h-3rem { min-height: 3rem !important;}
.min-h-4rem { min-height: 4rem !important;}
.min-h-5rem { min-height: 5rem !important;}
.min-h-6rem { min-height: 6rem !important;}
.min-h-7rem { min-height: 7rem !important;}




h1.color-orange {
  color: var(--orange);
}

.bg-gray-striped {
  background: repeating-linear-gradient(
    -45deg,
    var(--surface-b),
    var(--surface-b) 10px,
    var(--surface-d) 10px,
    var(--surface-d) 20px
  );
}

.crossed {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}


@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?yng8wa');
  src: url('fonts/icomoon.eot?yng8wa#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?yng8wa') format('truetype'),
  url('fonts/icomoon.woff?yng8wa') format('woff'),
  url('fonts/icomoon.svg?yng8wa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-harvest-box:before {
  content: "\68";
}

.icon-profile:before {
  content: "\e923";
}

.icon-wheelbarrow:before {
  content: "\e902";
}

.icon-euro:before {
  content: "\e93c";
}

.icon-location:before {
  content: "\e947";
}

.icon-discount:before {
  content: "\e900";
}

.icon-wheel-barrow:before {
  content: "\e900";
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--green);
  background: white;
}

.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background: var(--green);
}

.p-dialog .p-dialog-header {
  background-color: white;
  color: var(--green);
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: var(--green);
}

.p-selectbutton .p-button {
  background-color: transparent;
  border: 1px solid var(--green);
  border-collapse: collapse;
}

.p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button.p-highlight:hover {
  background-color: var(--green);
  border-color: transparent;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--green);
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  color: white;
}

.p-checkbox {
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-focus {
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  box-shadow: none;
  border-color: var(--green);
  background: var(--green);
}

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover,
.p-togglebutton.p-button:focus {
  border-color: var(--green);
  background: var(--green);
  box-shadow: none;
}

.p-checkbox.error .p-checkbox-box {
  border-color: var(--error);
  background: var(--error-10);
}

.p-slider .p-slider-range {
  background: var(--green);
}

button:not(p-button-icon-only).p-button,
button:not(p-button-icon-only).p-button:enabled:active,
button:not(p-button-icon-only).p-button:enabled:hover,
button:not(p-button-icon-only).p-button:enabled:focus,
button:not(p-button-icon-only).p-button:enabled:visited {
  background-color: var(--green);
  color: white;
  border: 0;
  outline: none;
  box-shadow: none;
}

.p-button.p-button-outlined,
.p-button.p-button-outlined:enabled:focus {
  background-color: transparent;
  color: var(--orange);
  border: 1px solid var(--orange);
}

.p-button.p-button-outlined:enabled:hover {
  color: white;
  background-color: var(--orange);
  border: 1px solid var(--orange);
}


button.p-button.p-button-link, button.p-button:enabled:active.p-button-link, button.p-button:enabled:hover.p-button-link, button.p-button:enabled:focus.p-button-link, button.p-button:enabled:visited.p-button-link {
  color: var(--green);
  border: 0;
  outline: none;
  background-color: transparent;
}

button.p-button.p-button-link .p-button-text, button.p-button:enabled:active.p-button-link .p-button-text, button.p-button:enabled:hover.p-button-link .p-button-text, button.p-button:enabled:focus.p-button-link .p-button-text, button.p-button:enabled:visited.p-button-link .p-button-text {
  padding: 0;
  font-size: 14px;
}

.input-number-stepper.p-inputnumber-buttons-horizontal {
  display: flex;
  align-items: center;
}

.input-number-stepper.p-inputnumber-buttons-horizontal .p-inputtext {
  width: 3rem;
  padding: 0.5rem;
  text-align: center;
}

.input-number-stepper.p-inputnumber-buttons-horizontal .p-button {
  margin: 0 0.25rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  background: transparent;
  color: var(--green);
  border: 1px solid var(--green);
}

.input-number-stepper.p-inputnumber-buttons-horizontal .p-button .pi {
  font-size: 0.75rem;
}

.p-inputgroup .p-inputnumber-buttons-stacked.flex-row-reverse.p-inputwrapper:first-child > .p-inputtext,
.p-inputgroup .p-inputnumber-buttons-horizontal.p-inputwrapper:first-child > .p-inputtext {
  border-radius: 0;
}

.p-inputnumber-buttons-stacked.flex-row-reverse .p-button.p-inputnumber-button-up,
.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up
{
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: auto;
  padding: 0 2px;
  background-color: transparent;
  color: var(--green);
}

.p-inputnumber-buttons-stacked.flex-row-reverse .p-button.p-inputnumber-button-down,
.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down
{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 6px;
  width: auto;
  padding: 0 2px;
  background-color: transparent;
  color: var(--green);
}

.sf-table .p-datatable-table {
  height: 100%;
}

.sf-table .p-datatable-table td {
  height: 100%;
}

.sf-table.p-datatable .p-datatable-header {
  border: 0;
  background: 0;
  padding: 0;
  margin: 0;
  margin-bottom: 0.75rem;
}

.sf-table.p-datatable .p-column-header-content .p-column-title {
  display: block;
  width: 100%;
  font-size: 1rem;
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead {
  background: transparent;
}

.sf-table.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.25rem;
  background: white;
  border: 0;
  border-bottom: 2px solid var(--surface-500);
  font-weight: 700;
  text-align: left;
  vertical-align: bottom;
}

.sf-table.p-datatable .p-datatable-tbody > tr > td {
  background: inherit;
  border: 0;
  border-bottom: 1px solid var(--surface-300);
  border-bottom-color: var(--surface-300) !important;
  padding: 0.25rem;
}

.sf-table.p-datatable .p-datatable-tbody > tr.topAlign > td {
  vertical-align: top;
}

.sf-table.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: unset;
}


.sf-table.p-datatable .p-datatable-tbody > tr.p-rowgroup-footer > td {
  border-bottom: 0;
}

.sf-table.p-datatable .p-datatable-tfoot > tr > td {
  background: transparent;
  padding: 4px;
}

.sf-table.p-datatable .p-datatable-footer {
  background: transparent;
}

.sf-table.p-treetable .p-treetable-thead > tr > th {
  padding: 0.5rem 0;
  background: transparent;
  border: 0;
  border-bottom: 2px solid var(--surface-500);
}

.sf-table.p-treetable .p-treetable-tbody > tr > td {
  padding: 0;
}

.sf-table.p-treetable .p-treetable-header {
  background: var(--surface-0);
  border: 0;
}
.bRkcDX.p-datatable .p-datatable-footer {
  /* text-align: right; */
  background: transparent;
}

.p-datatable .p-datatable-footer {
  color: #343a40;
  border: 0;
  padding: 0.5rem 0 1rem;
  font-weight: 700;
}

.product-unit-viewer .p-selectbutton .p-button {
  border: 0;
  border-radius: 0;
}

.product-unit-viewer .p-selectbutton .p-button:not(.p-highlight) .p-button-label {
  font-weight: normal;
  color: var(--surface-400) !important;
}

.p-selectbutton-dense .p-button {
  padding: 0.25em 1em;
}

.p-button {
  font-family: Montserrat,sans-serif;
}

.p-button .p-button-icon.pi {
  font-size: inherit;
}

.p-dense.p-dropdown .p-dropdown-label {
  padding: 0.25rem;
}

.p-dense.p-dropdown .p-dropdown-trigger {
  width: auto;
  margin-right: 0.25rem;
}

.p-dense.p-dropdown .p-dropdown-clear-icon {
  right: 1.5rem;
}

.p-dense.p-button,
.p-dense.p-inputtext{
  padding: 0.25rem;
}

.text-2xs {
  font-size: 0.5rem !important;
}


.square {
  position: relative;
}

.square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.p-button-dense {
  padding: 0.125rem 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
}

.p-button-dense.p-button-icon-only {
  padding: 0 0.5rem;
}







.image-container {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
}

/* The gradient overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, white, transparent);
}

/* The text container on top of the image */
.text-container {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  color: white;
  text-align: center;
  z-index: 2; /* Ensure text stays on top of the overlay */
}

.text-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1.2rem;
}


